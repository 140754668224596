import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import Gallery from "../../../components/Gallery/gallery"

const OfficeProducts = () => (
  <Layout>
    <SEO title="Office Products" />
    <HeroInner title="Office Products" imageAlt="Office Products" imagePath="services/retail-products/office-products/hero.jpg" />
    <ArticleFullWidth
      descriptions={[
        `List of Categories & Product Examples for Office Products Category`,
      ]}
    />

    <Gallery
      items={[
        {
          name: "Writing Supplies",
          imagePath: "services/retail-products/office-products/writing-supplies.jpg",
        },
        {
          name: "NoteBook & Pads",
          imagePath: "services/retail-products/office-products/notebook-and-pad.jpg",
        },
        {
          name: "Binders, Files & Folder",
          imagePath: "services/retail-products/office-products/binders-files-and-folders.jpg",
        },
        {
          name: "Office Basics",
          imagePath: "services/retail-products/office-products/office-basics.jpg",
        },
        {
          name: "Desk Organizers & Accessories",
          imagePath: "services/retail-products/office-products/desk-organizers-and-accessories.jpg",
        },
        {
          name: "Printer Paper",
          imagePath: "services/retail-products/office-products/printing-paper.jpg",
        },
        {
          name: "Calculator",
          imagePath: "services/retail-products/office-products/calculator.jpg",
        },
        {
          name: "Computer Accessories",
          imagePath: "services/retail-products/office-products/computer-accessories.jpg",
        },
        {
          name: "Tapes",
          imagePath: "services/retail-products/office-products/tapes.jpg",
        },
      ]}
    />
  </Layout>
)

export default OfficeProducts
